<template id="bussiness-card">
  <v-row
    :align="$vuetify.breakpoint.mobile ? null : 'center'"
    style="min-height: 100vh"
    justify="center"
    class="fill-height"
  >
    <v-progress-circular
      indeterminate
      size="45"
      color="primary"
      :class="{ 'mt-8': $vuetify.breakpoint.mobile }"
      v-if="loading"
    ></v-progress-circular>
    <v-col cols="12" sm="5" v-else>
      <v-card class="mx-auto">
        <v-card-text class="text--primary">
          <v-row align="center" no-gutters justify="center" class="mt-2">
            <img
              style="max-height: 100px; max-width: 100px"
              alt="Logo"
              class="ui centered image client-logo"
              :src="organization.logo"
            />
          </v-row>

          <v-row align="center" no-gutters justify="center" class="my-4">
            <div class="text-h6 text-center">CARTÃO DE ENTREGA ONLINE</div>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <span>Pedido de Exame: {{ card.ate_seqnum }}</span>
              <v-divider class="my-4" />
            </v-col>

            <v-col cols="12" class="mt-2">
              <div class="font-weight-medium">Unidade</div>
              <div>{{ card.emp_nomfan }}</div>
            </v-col>
            <v-col cols="12" class="mt-2">
              <div class="font-weight-medium">Paciente</div>
              <div>{{ card.pac_nome }}</div>
            </v-col>
            <v-col cols="12" class="mt-2">
              <div class="font-weight-medium">Solicitante</div>
              <div>{{ card.med_nome }}</div>
            </v-col>
            <v-col cols="12" class="mt-2">
              <div class="font-weight-medium">Convênio</div>
              <div>{{ card.con_nomefant }}</div>
            </v-col>
            <v-col cols="12" class="mt-2">
              <div class="text-h6">Exames(s):</div>
              <v-divider class="my-2" />
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Exame</th>
                      <th class="text-left">Entrega</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(exam, index) in card.exames" :key="index">
                      <td>{{ exam.pro_nome }}</td>
                      <td>{{ exam.preventrega }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br />
              <div v-html="customText"></div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Api from '../resources/NajaSocialApi'
import { mapState } from 'vuex'

export default {
  name: 'bussiness_card',
  computed: {
    ...mapState({
      organization: state => state.data.organization,
    }),
  },

  data() {
    return {
      card: {
        exames: [],
      },
      errorMsg: 'Agendamento não encontrado',
      loading: false,
      showError: false,
      customText: '',
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      this.loading = true
      this.showError = false

      Api.businessCard(this.$route.params.token)
        .then(response => {
          this.card = response.data.card
          this.customText = response.data.custom_text
        })
        .catch(error => {
          error.response.data.error
          if (_.isEmpty(error.response.data.error)) {
            this.errorMsg = 'Agendamento não encontrado'
          } else {
            this.errorMsg = error.response.data.error
          }

          this.showError = true
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
.main {
  padding: 1rem;
  background: white;
  border-radius: 0.5rem;
}
</style>
